import React ,{useState} from 'react'
import { cities } from '../data'
import car_list from '../../static/car-list.json'
import PopUp from './Modal'


function QuoteForm() {
  const [brandName, setBrandName] = useState('')
  const [modelName, setModelName] = useState([])
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [mobileNumber, setMobileNumber] = useState(null)

  function getBrandModels(newBrandName){
    setBrandName(newBrandName)
    for(let i=0; i<car_list.length;i++){
      if(car_list[i].brand === newBrandName){
        setModelName(car_list[i].models)
      }
    }
  }

  const handleQuoteSubmitClick = () => {
    if(mobileNumber){
      setShowSuccessMessage(true);
    }
    else{
      alert('Please fill details !!!!')
    }
  }  
  const closeSuccessMessage = ()=>{
    setShowSuccessMessage(false)
  }
 

  return (
    <div className='w-100 row m-0'>
      <h3 className='quoteform-heading1'>Experience the Best Car Services</h3>
      <h6 className=' quoteform-heading2 '>Get the instant quotes for your car!</h6>
      <div className=' col-lg-6 col-12'>
        <div>
          <div className="form-group">
            <select className="form-select mt-3 " aria-label="Select Brand" 
            onChange={(e)=>{
              getBrandModels(e.target.value)
            }}
            >
            <option >Select Brand</option>
              {car_list.map((car) => {
                return (
                  <option 
                  key={car.brand} 
                  value={car.brand}
                  selected={brandName===car.brand}
                  >{car.brand}</option>
                )
              })}
            </select>
            <select 
            className="form-select mt-3 " aria-label="Select Model" 
              disabled={brandName===''} >
              <option  value=''>Select Model</option>
              {modelName.map((model_name) => {
                return (
                  <option key={model_name} selected={modelName===model_name} value={model_name}>{model_name}</option>

                )
              })}
            </select>
            <select className="form-select mt-3 " aria-label="Select City">
              <option selected>Select City</option>
              {cities.map((city) => {
                return (
                  <option key={city.city_name} value={city.city_name}>{city.city_name}</option>
                )
              })}
            </select>
            <input 
            type="number" 
            className="form-control mt-3 " 
            placeholder="Enter Mobile Number" 
            value={mobileNumber}
            onChange={(e)=>{setMobileNumber(e.target.value)}}
            />
          </div>
          <button 
          onClick={()=>{handleQuoteSubmitClick()}}
          className="btn btn-primary custom-button mt-2">
            Get a quote
          </button>
        </div>
        <PopUp 
        showSuccessMessage={showSuccessMessage}
         closeSuccessMessage={closeSuccessMessage}
         />
      </div>
    </div>
  )
}

export default QuoteForm