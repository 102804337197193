import React from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from './Components/AppNavbar/Navbar';
import Home from './Components/AppBody/Home/Home';
import Footer from './Components/AppFooter/Footer';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import About from './Components/AppBody/AboutComponent/About'
import Contact from './Components/AppBody/ContactComponent/Contact'
import Services from './Components/AppBody/ServicesPage/Service'
import Login from './Components/AppBody/LoginComponent/Login'
import './App.css';

function App() {
  return (
    <div className='app'>
      <Navbar />
      <div className='app-body '>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/services" element={<Services/>}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

