import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';

const LoginForm = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showWarning, setShowWarning] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation and login logic here
    handleLogin(username);
  };
  const showWarningFunc = () => {
    setShowWarning('Invalid login details. Please, enter valid information!')
  }

  return (
    <div className='d-flex justify-content-center align-items-center h-75'>
      <div>
        {window.innerWidth > 768
          ? <div>
            <h1 style={{ color: 'white' }}>Login!</h1>
            <h4 style={{ color: 'white' }}>Please Enter Your Details!</h4>
          </div>
          : null}

        <div className='w-100 mt-3'>
          <div class="form-group ">
            <label for="exampleInputuser" style={{ color: 'white', fontSize: '18px', padding: '5px' }}>Username</label>
            <input type="text" class="form-control" id="exampleInputuser" placeholder="Enter username" />
          </div>
          <div class="form-group mt-2">
            <label for="exampleInputPassword1" style={{ color: 'white', fontSize: '18px', padding: '5px' }}>Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
          </div>

          <button class="btn btn-primary mt-4" onClick={() => { showWarningFunc() }}>Submit</button>
          {showWarning === '' ?
            null
            : <div style={{ color: 'red' }}>{showWarning}</div>
          }
        </div>
      </div>

    </div>

  )
  // return (
  //   <Form onSubmit={handleSubmit}>
  //     <Form.Group controlId="username">
  //       <Form.Label>Username</Form.Label>
  //       <Form.Control
  //         type="text"
  //         placeholder="Enter username"
  //         value={username}
  //         onChange={(e) => setUsername(e.target.value)}
  //       />
  //     </Form.Group>

  //     <Form.Group controlId="password">
  //       <Form.Label>Password</Form.Label>
  //       <Form.Control
  //         type="password"
  //         placeholder="Password"
  //         value={password}
  //         onChange={(e) => setPassword(e.target.value)}
  //       />
  //     </Form.Group>

  //     <Button variant="primary" type="submit">
  //       Login
  //     </Button>
  //   </Form>
  // );
};

export default LoginForm;
