import React, { useState } from "react";
import "./Home.css";
import BrandComponent from "./Brands";
import QuoteForm from "./QuoteForm";
import Servicescomponent from "./Servicescomponent";
import { workflow, cities } from "../data";
import partners from "./trusted-partners.png";
import PopUp from "./Modal";

function Home() {
  const text1 = "Our goal is Your Satisfaction!";
  const text2 =
    "Welcome to Motor Mechanic, your trusted provider of ‘One Touch Vehicle Solution’ for all your Car & Two-wheeler care needs, including services, repairs, dry cleaning,rubbing & polishing, cashless insurance claim processing, denting & painting body shop, car scanning, and roadside assistance .";
  const text3 =
    "Welcome to Motor Mechanic, your trusted provider of ‘One Touch Vehicle Solution’ for all your Car & Two-wheeler care needs.";

  return (
    <div style={{}} className="w-100">
      <div className=" container-fluid p-0 m-0">
        <div className="image-component ">
          <div
            className="component-wrapper   home-background"
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <div className="component background-component   ">
              <div className="shadow-lg home-text-box">
                <div className="display-text1  ">{text1}</div>
                <div className="display-text2">
                  {window.innerWidth > 768 ? text2 : text3}
                </div>
                {window.innerWidth > 768 ? <QuoteForm /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="responsive-form shadow-lg">
        {window.innerWidth <= 768 ? <QuoteForm /> : null}
      </div>
      <div className=" container-fluid p-0 m-0">
        <PopUp/>
      </div>
      <div className=" container-fluid p-0 m-0">
        <BrandComponent />
      </div>
      <div className=" container-fluid p-0 m-0">
        <Servicescomponent />
      </div>

      <div className=" container-fluid p-0 component-wrapper">
        <div className="component">
          <h2 className="heading">How Motor Mechanic Works?</h2>
          <div className="row p-2 m-0 w-100 d-flex justify-content-center ">
            {workflow.map((item) => {
              return (
                <div className=" col-12 col-md-6 col-lg-3 workflow-image-div ">
                  <div className="shadow p-4 rounded">
                    <img className="workflow-image" src={item.image} />
                    <div className="mt-2 workflow-image-caption">
                      {item.heading}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className=" container-fluid p-0 pb-3 component-wrapper app-dark-background-color">
        <div className="component d-flex justify-content-center align-items-center p-2">
          <div>
            <h2 className="heading">Our Trusted Partners</h2>
            <img className="partners-image-component" src={partners} />
          </div>
        </div>
      </div>
      <div className=" container-fluid p-0 pb-5 component-wrapper app-light-background-color w-100 ">
        <div className="component d-flex justify-content-center align-items-center p-2 w-100">
          <div className=" w-100">
            <h2 className="heading">Cities We Serve</h2>
            <div className="container w-100 cities-component">
              {cities.map((city) => {
                return (
                  <div className="m-2">
                    <div className="d-flex justify-content-center ">
                      <img
                        src={city.city_icon}
                        style={{ borderRadius: "50%", height: "60px" }}
                      />
                    </div>
                    <div className=" d-flex justify-content-center ">
                      {city.city_name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
}

export default Home;
