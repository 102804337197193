import React, { useState } from 'react';
// import { Form, Button } from 'react-bootstrap';
import LoginForm from './LoginForm';
const Login = ({ handleLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation and login logic here
    handleLogin(username);
  };
  return (
    <div className=' container-fluid p-0 m-0'>
        <div className='image-component ' >
    <div className='component-wrapper   home-background ' style={{ position: 'absolute', top: 0, right: 0 }}>
    <div className='component background-component w-100 '>
      <div className='shadow-lg home-text-box w-100 ' style={{height:'90%'}}>
          <LoginForm />
      </div>
    </div>

  </div>
  </div>
  </div>
  )
//   return (
//     <Form onSubmit={handleSubmit}>
//       <Form.Group controlId="username">
//         <Form.Label>Username</Form.Label>
//         <Form.Control
//           type="text"
//           placeholder="Enter username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//         />
//       </Form.Group>

//       <Form.Group controlId="password">
//         <Form.Label>Password</Form.Label>
//         <Form.Control
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//       </Form.Group>

//       <Button variant="primary" type="submit">
//         Login
//       </Button>
//     </Form>
//   );
};

export default Login;
