import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";

const PopUp = (props) => {
    
    return (
      <>
       
        <Modal show={props.showSuccessMessage} onHide={props.closeSuccessMessage}  style={{marginTop:'30vh'}}>
          <Modal.Header closeButton>
          </Modal.Header>
          <div style={{height:'170px'}}>
          <Modal.Body className="h-100 d-flex  ">
            <div className="p-2">
            <div className="d-flex justify-content-center mb-2" style={{fontSize:'40px',color:'var(--app-color'}}><CheckCircleFill/></div>
            <h4 style={{color:'var(--app-color'}}>Your Enquiry is successfully Registered.</h4>
            <div>Thank You, for contacting us.We will reach out you soon!</div>
            </div>
            
          </Modal.Body>
          </div>
          
        </Modal>
      </>
    );
}

export default PopUp;
