import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { services } from '../data'

function Servicescomponent() {
    const navigate = useNavigate()
    function navigate_to_services() {
        navigate('/services')
    }
    return (
        <div className='component-wrapper service-component app-dark-background-color '>
            <div className=' component'>
                <h2 className=' heading'>Car Services we provide</h2>
                <div className='bg-light row m-5 '>
                    {services.map((service) => {
                        return <div className=' col col-lg-2 col-4 shadow  d-flex justify-content-center align-items-center' onClick={navigate_to_services}>
                            <div className='   mt-3  ' style={{ width: '100%' }}>
                                {Array.isArray(service.icons) ?
                                    <div style={{ height: '70%', paddingTop: '5%' }} className=' d-flex justify-content-center p'>
                                        <span style={{ height: '70%', width: '60%' }} className='d-flex justify-content-center'><img src={service.icons[0]} width='50%' /><img src={service.icons[1]} width='50%' /></span>
                                    </div>
                                    :
                                    <div className='d-flex justify-content-center'>
                                        <img src={service.icons} height='60%' width='50%' />
                                    </div>
                                }
                                <div className='d-flex justify-content-center align-items-center mt-4 ' style={{ fontSize: '75%', paddingBottom: '5%' }}>{service.heading}</div>
                            </div>
                        </div>
                    })}


                </div>



            </div>



        </div>
    )
}

export default Servicescomponent