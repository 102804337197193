import React, { useState } from 'react'
import QuoteForm from '../Home/QuoteForm'
import car from './car_wallpaper.jpg'
import './Service.css'
import { services_package } from '../data'
import { cities } from '../data'
import car_list from '../../static/car-list.json'
import PopUp from '../Home/Modal'

function Service() {
  const [brandName, setBrandName] = useState('')
  const [modelName, setModelName] = useState([])
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [mobileNumber, setMobileNumber] = useState(null)

  function onBrandSelect(newBrandName) {
    setBrandName(newBrandName)
    for (let i = 0; i < car_list.length; i++) {
      if (car_list[i].brand === newBrandName) {
        setModelName(car_list[i].models)
      }
    }
  }
  const handleQuoteSubmitClick = () => {
    if(mobileNumber){
      setShowSuccessMessage(true);
    }
    else{
      alert('Please fill details !!!!')
    }
  }  
  const closeSuccessMessage = ()=>{
    setShowSuccessMessage(false)
  }


  return (
    <div className=''>
      <div className='component-wrapper ' style={{ backgroundColor: 'rgb(0,0,12,0.9)' }}>
        <div className='component m-0 p-0'>
          <div className=' m-0 p-0 w-100'>
            <img src={car} width='100%' />
            <div className=' services-quoteform' >
              <div className='mx-2 select-div' >
                <select className="form-select " aria-label="Select Brand"
                  onChange={(e) => {
                    onBrandSelect(e.target.value)
                  }}
                >
                  <option selected >Select Brand</option>
                  {car_list.map((car) => {
                    return (
                      <option value={car.brand} selected={brandName===car.brand}>{car.brand}</option>

                    )
                  })}
                </select>
              </div >
              <div className='mx-2 select-div'>
                <select className="form-select  " aria-label="Select Model" disabled={brandName===''}>
                  <option selected>Select Model</option>
                  {modelName.map((model) => {
                    return (
                      <option value={model} selected={modelName===model}>{model}</option>

                    )
                  })}
                </select>
              </div>
              <div className='mx-2 select-div'>
                <select className="form-select " aria-label="Select City">
                  <option selected>Select City</option>
                  {cities.map((city) => {
                    return (
                      <option value={city.city_name}>{city.city_name}</option>
                    )
                  })}
                </select>
              </div>

              <div className='mx-2 select-div'>
                <input type="number" className="form-control" value={mobileNumber} placeholder="Enter Mobile Number" 
                 onChange={(e)=>{setMobileNumber(e.target.value)}}/>
              </div>
              <div className='mx-2 button-width'>
                <button
                  onClick={()=>{handleQuoteSubmitClick()}}

                 className="btn btn-sm btn-primary custom-button w-100">Get a quote</button>
              </div>
              <PopUp 
              showSuccessMessage={showSuccessMessage}
              closeSuccessMessage={closeSuccessMessage}
              />   
            </div>
          </div>
        </div>
      </div>
      <div className='component-wrapper app-dark-background-color'>
        <div className='component'>
          <div className='row  m-0 mt-2 mb-3 p-0 w-100 '>
            <div className='col-12'>
              {services_package.map((service_package) => {
                return (
                  <div className='bg-light mt-3 p-1 shadow rounded '>
                    <h4 className='heading mt-2'>{service_package.heading}</h4>
                    <div className='row m-0 p-3'>
                      {service_package.service_names.map((service_name) => {
                        return (
                          <div className='col-lg-3 col-md-4 col-sm-6 m-0 shadow-sm d-flex justify-content-center rounded'>
                            <div className='p-2'>
                              {service_name}
                            </div>
                          </div>
                        )

                      })}

                    </div>
                  </div>
                )
              })}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service