import React from 'react'
import { Whatsapp, Linkedin } from 'react-bootstrap-icons'
import instagram from './instagram-logo.ico'
import logo from './motologo.png'
import './footer.css'

function Footer() {
    return (
        <div className='app-footer component-wrapper' >

            <div className='component footer-container row w-100 '>
                <hr style={{ height: '5px' }}></hr>

                <div className='col-lg-4 col-md-12  d-flex  justify-content-center mt-4'>
                    <div style={{minWidth:'300px'}}>
                        <h5>Our Social Media</h5>
                        <div className='d-flex mt-5'>
                            <img src={instagram} height='40px' width='40px' className='rounded rounded-3 me-3' />
                            <Whatsapp style={{ fontSize: '40px', color: 'white', backgroundColor: '3EC70B' }} className='rounded rounded-4 mx-3' />
                            <Linkedin style={{ fontSize: '40px', backgroundColor: 'white', color: '1F4690' }} className=' rounded mx-3' />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-12 d-flex justify-content-center mt-4'>
                    <div style={{minWidth:'300px'}}>
                        <h5 className='company-heading'>Company</h5>
                        <div className='row mt-3 '>
                            <div className='col col-3'>
                                <p>Home</p>
                                <p>About</p>
                                <p>Services</p>
                                <p>Contact</p>
                            </div>
                            <div className='col col-6'>
                                <p>Work with us</p>
                                <p>Insurance claim</p>
                                <p>Career</p>
                                <p>Login</p>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='col-lg-4 col-md-12  d-flex justify-content-center mt-4'>
                    <div style={{minWidth:'300px'}}>
                        <h5>Contact Info</h5>
                        <div className='mt-4 '>
                            <img src={logo} height='100px' width='120px' />
                            <div>
                                <p>Address: 209A, Bijwasan,</p>
                                <p>South West Delhi-110061</p>
                                <p>Phone: +919810121393</p>
                                <p>E-mail: support@motormechanic.co.in</p>
                            </div>

                        </div>
                    </div>


                </div>

            </div>


        </div>
    )
}

export default Footer