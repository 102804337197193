import chevrolet from '../static/Images/BrandImages/chevrolet-logo2.png'
import fiat from '../static/Images/BrandImages/fiat-logo.png'
import honda from '../static/Images/BrandImages/hondalogo2.png'
import hyundai from '../static/Images/BrandImages/hyundai-logo2.png'
import maruti from '../static/Images/BrandImages/logo-maruti.png'
import mahindra from '../static/Images/BrandImages/mahindra-logo2.png'
import nissan from '../static/Images/BrandImages/nissan-logo2.png'
import tata from '../static/Images/BrandImages/tatalogo2.png'
import toyota from '../static/Images/BrandImages/toyota-logo2.png'
import volkswagen from '../static/Images/BrandImages/volkswagen-logo.png'
import volvo from '../static/Images/BrandImages/volvo-logo2.png'

import car_wash from '../static/Images/ServiceIconImages/carwash-2.png'
import oil_change from '../static/Images/ServiceIconImages/oil-change.png'
import wheel from '../static/Images/ServiceIconImages/wheel-alignment.png'
import denting from '../static/Images/ServiceIconImages/denting.png'
import fuel_system from '../static/Images/ServiceIconImages/fuel-system.png'
import service from '../static/Images/ServiceIconImages/car-service.png'
import brake from '../static/Images/ServiceIconImages/brake2.png'
import coolant from '../static/Images/ServiceIconImages/engine-coolant.png'
import transmission from '../static/Images/ServiceIconImages/transmission.png'
import insurance from '../static/Images/ServiceIconImages/car-insurance2.png'
import battery from '../static/Images/ServiceIconImages/car-battery.png'
import clutch from '../static/Images/ServiceIconImages/clutch2.png'
import car_door from '../static/Images/ServiceIconImages/car-door.png'
import pick_drop from '../static/Images/ServiceIconImages/car-key.jpg'
import repair from '../static/Images/ServiceIconImages/car-repair.jpg'
import warranty from '../static/Images/ServiceIconImages/handshake.jpg'
import genuine from '../static/Images/ServiceIconImages/Quality.jpg'

import Ahmedabad from '../static/icons/Ahmedabad.jpg'
import Bengaluru from '../static/icons/Bengaluru.jpg'
import Delhi from '../static/icons/Delhi.jpg'
import Hyderabad from '../static/icons/Hyderabad.jpg'
import Jaipur from '../static/icons/Jaipur.jpg'
import Lucknow from '../static/icons/Lucknow.jpg'
import Mumbai from '../static/icons/Mumbai.jpg'
import Pune from '../static/icons/Pune.jpg'
import Kanpur from '../static/icons/kanpur.jpg'
import Surat from '../static/icons/Surat.jpg'



export const car_brand_logo=[{image:chevrolet}
    ,{image:hyundai}
    
    ,{image:maruti}
    ,{image:fiat}
    ,{image:honda}
   
    ,{image:mahindra}
    ,{image:nissan,width:'100%'}
    ,{image:tata}
    ,{image:toyota}
    ,{image:volvo,width:'100%'}
    ,{image:volkswagen}
]

export const services=[
    {
        icons:car_wash,
        heading:'Car Spa & Cleaning',
    },
    {
        icons:oil_change,
        heading:'Conventional Oil Change' ,
    },
    {
        icons:wheel,
        heading:'Wheel Care' 
    },
    {
        icons:brake,
        heading:'Visual Brake Inspection'
    },
    {
        icons:fuel_system,
        heading:'Fuel System Cleaning' ,
    },
    {
        icons:service,
        heading:'Car Repair',
    },
    {
        icons:coolant,
        heading:'Coolant Exchange',
    },
    {
        icons:battery,
        heading:'Car Battery',
    },
    {
        icons:insurance,
        heading:'Insurance Claims'
    },
    {
        icons:[clutch,car_door],
        heading:'Clutch & Body Parts'
    },
    {
        icons:transmission,
        heading: 'Transmission Fluid Service',
    },
    {
        icons:denting,
        heading:'Denting & Painting',
    },
]

export const client_testimonials=[
    {
        testimony:'"Motor Mechanic never disappoints! Their professional team explains repairs clearly, and I trust them with my car. Highly recommended!"',
        client_name:'- Lokesh kumar',
    },
    {
        testimony:'"Convenient and reliable! Motor Mechanic takes care of all my car maintenance needs efficiently. Highly recommended."',
        client_name:'- Jai Kishan',
    },
    {
        testimony:'Motor Mechanic made dealing with insurance claims stress-free. They guided me throughout the process and secured a quick settlement."',
        client_name:'- Priya Sharma',
    },
    {
        testimony:'"Motor Mechanic went above and beyond to fix my car. Their dedication to customer satisfaction is exceptional."',
        client_name:'- Akanksha Pandey',
    },
    {
        testimony:'"Motor Mechanic fought for my rights in a major accident insurance claim. Their dedication ensured a fair settlement."',
        client_name:'- Kartik Verma',
    },
    {
        testimony:'"Motor Mechanic is my go-to repair shop. Their top-notch service and attention to detail make them stand out. Thank you!"',
        client_name:'- Ankit Gupta',
    },
   
]
export const workflow=[
    {
        image:pick_drop,
        heading:'Free Pick-up & Drop Service',
    },
    {
        image:repair,
        heading:'Free Diagnostics & Repair',
    },
    {
        image:warranty,
        heading:'30 days warranty',
    },
    {
        image:genuine,
        heading:'100% authentic parts',
    },
]
export const services_package=[
    {
        heading:'Basic Car Service',
        service_names:[
            'Wiper Fluid Top Up',
            'Oil Filter Replace',
            'Engine Oil Replacement',
            'Battery Water Top Up',
            'Spark Plugs Clean',
            'Exterior Body Washing',
            'Coolant Top Up',
            'Air Filter cleaning',
        ]
    },
    {
        heading:'Standard Car Service',
        service_names:[
            'Tyre Polish',
            'Oil Filter Replace',
            'Engine Oil Replace',
            'Exterior Body Washing',
            'Interior Car Washing',
            'Dashboard Polish',
            'Brake Pads Clean',
            'Spark Plugs Clean',
            'Battery Water Top Up',
            'Coolant Top Up',
            'Brake Fluid Top-Up',
            'Ac Filter Clean',
        ]
    },
    
    {
        heading:'Comprehensive Car Service',
        service_names:[
            'Wheel Balancing',
            'Wheel Alignment',
            'Tyre Rotation',
            'Tyre Polish',
            'Spark Plugs Clean',
            'Oil Filter Replace',
            'Gear Oil Top-Up',
            'Exterior Body Washing',
            'Interior Car Washing',
            'Dashboard Polish',
            'Brake Pads Clean',
            'Brake Fluid Top-Up',
            'Air Filter Replace',
            'Ac Filter Clean',
            'Battery Water Top Up',
            '40 Point Checkup'

        ],
        
    }
]


export const cities=[
    {
        city_icon:Delhi,
        city_name:'Delhi NCR'
    },
    {
        city_icon:Mumbai,
        city_name:'Mumbai'
    },
    {
        city_icon:Hyderabad,
        city_name:'Hyderabad'
    },
    {
        city_icon:Bengaluru,
        city_name:'Bengaluru'
    },
    {
        city_icon:Pune,
        city_name:'Pune'
    },
    {
        city_icon:Ahmedabad,
        city_name:'Ahmedabad'
    },
    {
        city_icon:Lucknow,
        city_name:'Lucknow'
    },
    {
        city_icon:Kanpur,
        city_name:'Kanpur'
    },
    {
        city_icon:Jaipur,
        city_name:'Jaipur'
    },
    {
        city_icon:Surat,
        city_name:'Surat'
    },
]

