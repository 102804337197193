import React ,{useState} from 'react'
import car_served from './about-images/car-icon.png'
import workshop from './about-images/workshop1.png'
import clients from './about-images/clients1.png'
import daily_service from './about-images/workshop2.png'
import './About.css'
import {client_testimonials} from '../data'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


function About() {
    const [testimonyDisplayIndex, setTestimonyDisplayIndex] = useState(0)
    const testimonyDisplayLength=1
    return (
        <div>
            <div className='app-dark-background-color '>
                <div className='about-image-component'>
                    <div className='component-wrapper about-background  h-100' style={{ position: 'absolute', top: 0, right: 0 }}>


                        <div className='component row w-100 d-flex justify-content-center  shadow-lg about-text-box'>
                            <div className=' col-12 col-md-4 m-0 p-2 pt-4 textbox '>

                                <h4 style={{ textAlign: 'center' }}>WHO <span style={{ color: '#f5560c' }}>WE ARE</span></h4>
                                <hr className='horizontal-line'></hr>
                                <div className='px-3 text-inside-textbox' >
                                    We provide '​One Touch Vehicle Solution'​​ for all your Car & Two wheeler care needs. With a network of multi branded service station across Delhi NCR. Motor Mechanic is standardizing the car & two wheeler service & repair industry.
                                </div>

                            </div>
                            <div className=' col-12 col-md-4 m-0 p-2 pt-4 textbox'>

                                <h4 style={{ textAlign: 'center' }}>OUR <span style={{ color: '#f5560c' }}>MISSION</span></h4>
                                <hr className='horizontal-line'></hr>
                                <div className='px-3 text-inside-textbox'  >
                                    We are your 'Trusted Car & Two wheeler Care Partner' offering the following advantages which include up to 40% Savings, 100% Genuine OEM/OES Spare parts, 100% Warranty.
                                    Our mission is to provide a seamless service experience to all our customers.
                                </div>

                            </div>
                            <div className=' col-12 col-md-3 m-0 p-2 pt-4 textbox'>

                                <h4 style={{ textAlign: 'center' }}>WHY <span style={{ color: '#f5560c' }}>CHOSE US</span></h4>
                                <hr className='horizontal-line'></hr>
                                <div className='text-inside-textbox'  >
                                    <li>Customer-Centric Approach</li>
                                    <li>Skilled & Certified Technicians</li>
                                    <li>Genuine Parts</li>
                                    <li>State-of-the-Art Facility</li>
                                    <li>Competitive Pricing</li>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='app-light-background-color component-wrapper d-flex align-items-center' style={{ height: '300px' }}>
                <div className='component'>
                    <div className='row w-100 p-3'>
                        <div className='col-6 col-md-3 ' style={{ textAlign: 'center' }}>
                            <img src={car_served} height='40px' width='35px' />
                            <h1 style={{ color: 'grey' }}>1000+</h1>
                            <h6>Cars Serviced</h6>
                        </div>
                        <div className='col-6 col-md-3' style={{ textAlign: 'center' }}>
                            <img src={workshop} height='40px' width='35px' />
                            <h1 style={{ color: 'grey' }}>500+</h1>
                            <h6>Workshop Network</h6>
                        </div>
                        <div className='col-6 col-md-3' style={{ textAlign: 'center' }}>
                            <img src={clients} height='40px' width='35px' />
                            <h1 style={{ color: 'grey' }}>2000+</h1>
                            <h6>Clients served</h6>
                        </div>
                        <div className='col-6 col-md-3' style={{ textAlign: 'center' }}>
                            <img src={daily_service} height='40px' width='35px' />
                            <h1 style={{ color: 'grey' }}>50+</h1>
                            <h6>Daily services</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='app-dark-background-color d-flex align-items-center justify-content-center'>
                <div className='component-wrapper w-100 '>
                    <div className='component w-100 mt-4 mb-3'>
                        <h5 style={{ textAlign: 'center' }}>CLIENT TESTIMONIALS</h5>
                        <h2 style={{ textAlign: 'center', color: 'var(--heading-color)' }}>100% Approved By Customers</h2>
                        {window.innerWidth >= 768
                            ?
                            <div>
                                <div className='row w-100 p-0 m-0  d-flex justify-content-center ' >
                                    {client_testimonials.map((client) => {
                                        return <div className='col-12 col-lg-4 col-md-6 mt-5 px-5'>
                                            <div class="card" style={{ width: "100%", height: '200px' }}>
                                                <div class="card-body">
                                                    <p class="card-text">{client.testimony}</p>
                                                    <div style={{ color: 'var(--heading-color)' }}><i>{client.client_name}</i></div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            : 
                            <div className='d-flex justify-content-evenly '>
                                <div className='d-flex align-items-center justify-content-center'
                                    onClick={() => {
                                        if (testimonyDisplayIndex > 0) {
                                            let newIndex = testimonyDisplayIndex - testimonyDisplayLength
                                            if (newIndex < 0) {
                                                newIndex = 0
                                            }
                                            setTestimonyDisplayIndex(newIndex)
                                        }
                                    }}>
                                    <ArrowBackIosIcon />
                                </div>
                                <div className='w-75 overflow-hidden d-flex justify-content-center p-3'>
                                    {client_testimonials.map((client, index) => {

                                        if (index >= testimonyDisplayIndex && index < (testimonyDisplayIndex + testimonyDisplayLength)) {
                                            return <div className='col-10  mt-5 '>
                                            <div class="card" style={{ width: "100%", height: '200px' }}>
                                                <div class="card-body">
                                                    <p class="card-text">{client.testimony}</p>
                                                    <div style={{ color: 'var(--heading-color)' }}><i>{client.client_name}</i></div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    })}
                                </div>

                                <div className='d-flex align-items-center justify-content-center'
                                    onClick={() => {
                                        if (testimonyDisplayIndex < client_testimonials.length - testimonyDisplayLength) {
                                            let newIndex = testimonyDisplayIndex + testimonyDisplayLength
                                            if (newIndex >= client_testimonials.length - 1) {
                                                newIndex = client_testimonials.length - testimonyDisplayLength
                                            }
                                            setTestimonyDisplayIndex(newIndex)
                                        }
                                    }}>
                                    <ArrowForwardIosIcon />
                                </div>
                            </div>
                        }
                </div>
            </div>
        </div>
        </div >
    )
}

export default About
