import React from 'react'
import connect from './connect.jpg'
import './Contact.css'

function Contact() {
    return (
        <div>
            <div className='component-wrapper app-dark-background-color contact-component1'>
                <div className="component p-4 ">
                    <div className=' w-100  d-flex align-items-center' >
                        <div className='pt-4'>
                            <h6 >CONTACT US</h6>
                            <h2 style={{ color: 'var(--app-color' }}>Get In Touch</h2>
                            <div className='mt-4'>Reach out to us on <b style={{ color: 'var(--app-color' }}>+91-9810121393</b> .We are here at your service 24/7  by  e-mail or by phone. Drop us line so we can talk futher about that.</div>
                            <div className='mt-3'><b>For more info or inquiry about our products, project, and pricing please feel free to get in touch with us.</b></div>
                            <h6 className='mt-2'>Email:</h6>
                            <div >support@motormechanic.co.in</div>
                            <h6 className='mt-2'>Address:</h6>
                            <div>209A, Bijwasan, South West Delhi-110061</div>
                        </div>
                        <div className='flex-grow-1'></div>
                        <div className='contact-image'>
                            <img src={connect} height='300px' width='400px' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="component-wrapper app-light-background-color d-flex justify-content-center ">
                <div className='component  row mt-4 p-3 m-0'>
                    <h4 className='mb-2'>Send Us A Message!</h4>

                    <div className='col col-6'>
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control mb-4" placeholder="Enter Name" />
                                <input type="email" className="form-control mb-4" aria-describedby="emailHelp" placeholder="Enter email" />
                                <input type="text" className="form-control mb-4" placeholder="Subject" />
                                <textarea className="form-control mb-4" placeholder='Write your message...' ></textarea>
                            </div>
                            <button className="btn btn-primary">Submit</button>
                        </form>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Contact