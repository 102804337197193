import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from './motor-mechanic-logo.jpg'
import './Navbar.css'
import { Reorder } from '@mui/icons-material';

function Navbar() {

    const [showDropdown, setShowDropdown] = useState(false)
    const fullNavbar = <div className='app-navbar component-wrapper'>
        <div className='component d-flex align-items-center justify-content-center '>
            <div className=' d-flex align-items-center flex-grow-1'>
                <img src={logo} height='50px' width='80px' />
                <div className='ms-3  nav-title' style={{ fontSize: '35px' }}>Motor Mechanic</div>
            </div>
            <div className=' d-flex align-items-center'>
                <div className="p-3 ">
                    <Link to="/" className='custom-link'> <div  className="nav-item">Home</div></Link>
                </div>
                <div className="p-3" >
                    <Link to='services' className='custom-link'> <div  className="nav-item">Services</div></Link>
                </div>
                <div className="p-3 ">
                    <Link to="/about" className='custom-link'> <div  className="nav-item">About</div></Link>
                </div>

                <div className="p-3 ">
                    <Link to="/contact" className='custom-link'> <div className="nav-item" >Contact Us</div></Link>
                </div>
                <div className="p-3 cursor-pointer">
                <Link to="/login" className='custom-link'> <div className="app-button" >Login</div></Link>
                    
                </div>
            </div>
        </div>
    </div>

    const shrinkedNavbar = <div className='app-navbar ' style={{ position: 'relative' }}>
        <div className=' d-flex align-items-center justify-content-center h-100'>
            <div className=' d-flex align-items-center flex-grow-1'>
                <img src={logo} height='30px' width='50px' />
                <div className='ms-3  nav-title' style={{ fontSize: '20px' }}>Motor Mechanic</div>
            </div>
            <div className='toggle-button cursor-pointer' onClick={() => { setShowDropdown(!showDropdown) }}><Reorder /></div>
        </div>
        {showDropdown ?
            <div className='w-100 bg-light pb-2' style={{ minHeight: '300px', width: '100%', zIndex: 10000, position: 'absolute', top: 70, left: 0 }}>
                <div className="p-3 ">
                    <Link to="/" className='custom-link '> <div onClick={() => { setShowDropdown(false) }} className="nav-item" >Home</div></Link>
                </div>
                <div className="p-3" >
                    <Link to='/services' className='custom-link '> <div  onClick={() => { setShowDropdown(false) }}className="nav-item" >Services</div></Link>
                </div>
                <div className="p-3 ">
                    <Link to="/about" className='custom-link'> <div  onClick={() => { setShowDropdown(false) }}className="nav-item" >About</div></Link>
                </div>

                <div className="p-3 ">
                    <Link to="/contact" className='custom-link'> <div  onClick={() => { setShowDropdown(false) }}className="nav-item"  >Contact Us</div></Link>
                </div>
                <div className="p-3  cursor-pointer">
                <Link to="/login" className='custom-link'> <div onClick={() => { setShowDropdown(false) }} className="app-button" >Login</div></Link>
                </div>
            </div>
        :null
        }

    </div>

    const showFullNavbar = window.innerWidth >= 768
    return (
        <>
            {showFullNavbar ? fullNavbar : shrinkedNavbar}

        </>
    )
}

export default Navbar