import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { car_brand_logo } from '../data'

function Brands() {
    const [brandDisplayIndex, setBrandDisplayIndex] = useState(0)
    let brandDisplayLength = 5
    if (window.innerWidth <= 768) {
        brandDisplayLength = 3
    }
    return (
        <div className=' component-wrapper brand-component app-light-background-color ' >
            <div className='component  d-flex justify-content-center align-items-center'>
                <div className='w-100 overflow-hidden'>
                    <h2 className=' heading '>Brands We Serve</h2>
                    <div className='d-flex justify-content-evenly '>
                        <div className='d-flex align-items-center justify-content-center'
                            onClick={() => {
                                if (brandDisplayIndex > 0) {
                                    let newIndex = brandDisplayIndex - brandDisplayLength
                                    if (newIndex < 0) {
                                        newIndex = 0
                                    }
                                    setBrandDisplayIndex(newIndex)
                                }
                            }}>
                            <ArrowBackIosIcon />
                        </div>
                        <div className='w-75 overflow-hidden d-flex justify-content-center p-3'>
                            {car_brand_logo.map((brand, index) => {

                                if (index >= brandDisplayIndex && index < (brandDisplayIndex + brandDisplayLength)) {
                                    return <div className='shadow d-flex align-items-center justify-content-center mx-3 brand-logo'>
                                        <img src={brand.image} height={brand.height ? brand.height : '90%'} width={brand.width ? brand.width : '90%'} />
                                    </div>
                                }

                            })}
                        </div>

                        <div className='d-flex align-items-center justify-content-center'
                            onClick={() => {
                                if (brandDisplayIndex < car_brand_logo.length - brandDisplayLength) {
                                    let newIndex = brandDisplayIndex + brandDisplayLength
                                    if (newIndex >= car_brand_logo.length - 1) {
                                        newIndex = car_brand_logo.length - brandDisplayLength
                                    }
                                    setBrandDisplayIndex(newIndex)
                                }
                            }}>
                            <ArrowForwardIosIcon />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Brands